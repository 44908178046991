.Footer{
  display: flex;
  flex-direction: row;
  background-color: #09090D;
  margin-top: 90px;
  min-height: 400px;
  padding: 90px 40px 25px;

  @include breakpoint('mobile', max){
    min-height: 250px;
  }

  &__content{
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 1286px;
    margin-left: auto;
    margin-right: auto;
  }

  .SocialIcons{
    margin-top: 40px;
  }

  &__copyright{
    margin-top: auto;
    font-size: 12px;
    line-height: 14px;
    color: #555773;
  }

  &__col1{
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: 20px;
    min-height: 250px;

    @include breakpoint('mobile', max){
      min-height: unset;
    }
  }

  &__col2{
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: 20px;

    @include breakpoint("mobile", max) {
      display: none;
    }
  }

  &__col3{
    display: flex;
    flex-direction: column;
    flex: 1;

    @include breakpoint("mobile", max) {
      display: none;
    }
  }

  .NavbarLink{
    display: block;
    margin-left: 20px;
    margin-right: 20px;
    height: 18px;
    margin-bottom: 14px;
    margin-left: 0;
    font-size: 16px;
    color: #555773;
    font-weight: normal;

    &:hover{
      color: $color-green;
    }

    .NavbarLink__border{
      display: none !important;
    }

    .NavbarLink__external-link{
      margin-bottom: 5px;
    }
  }
}