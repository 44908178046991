.ComponentsPage{
  display: flex;
  flex-direction: column;
  align-items: center;

  .TopSection{
    display: flex;
    flex-direction: row;
    max-width: calc(1366px - 120px);
    padding-top: calc(120px + $navbar-height);

    @include breakpoint("mobile", max){
      flex-direction: column;
      padding-top: calc(50px + $navbar-height-mobile);
    }

    .TextContainer{
      display: flex;
      justify-content: center;
      flex-direction: column;
      min-width: 520px;

      @include breakpoint("desktop", max){
        min-width: 420px;
      }

      @include breakpoint("tablet-wide", max){
        min-width: 320px;
      }

      @include breakpoint("mobile", max){
        min-width: unset;
        width: 100%;

        h1{
          width: 260px;
          margin-left: auto;
          margin-right: auto;
        }

        h1, p{
          text-align: center;
        }
      }
    }

    .ImageContainer{
      margin-left: 120px;

      img{
        max-height: 478px;
      }

      @include breakpoint("desktop", max){
        margin-left: 70px;

        img{
          max-height: 378px;
        }
      }

      @include breakpoint("tablet-wide", max){
        margin-left: 40px;

        img{
          max-height: 300px;
        }
      }

      @include breakpoint("mobile", max) {
        margin-left: 0;
        margin-top: 33px;

        img{
          max-height: unset;
          width: 100%;
        }
      }
    }
  }

  .ComponentSection{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow-y: hidden;
    overflow-x: hidden;
    padding-top: calc(120px + $navbar-height);

    @include breakpoint("mobile", max) {
      padding-top: calc(80px + $navbar-height-mobile);
    }

    .SectionContainer{
      max-width: 1365px;
    }

    &__graphic{
      width: 100%;
      max-width: 1182px;
      margin-top: 30px;
    }

    &--graphic01{
      max-width: 1182px;
    }

    &--graphic02{
      max-width: 1182px;
    }

    &--graphic03{
      max-width: 1222px;
    }

    &--graphic04{
      max-width: 1094px;
      margin-bottom: 30px;
    }

    &--graphic04a{
      max-width: 742px;
      margin-top: 70px;
      margin-bottom: 24px;

    }

    &--graphic05{
      max-width: 1200px;
    }

    h1{
      margin-bottom: 12px;
    }

    h1, h2, p{
      text-align: center;
    }

    h3{
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 12px;

      @include breakpoint("mobile", max) {
        font-size: 16px;
        line-height: 19px;
      }
    }

    p{
      max-width: 700px;
    }

    &--01{
      h1{
        max-width: 839px;
      }
    }

    &--02{
      h1{
        max-width: 723px;
      }
    }

    &--03{
      h1{
        max-width: 880px;
      }
    }

    &--04{
      h1{
        max-width: 642px;
      }
    }

    &--05{
      padding-bottom: 100px;

      h1{
        max-width: 627px;
      }
    }
  }
}