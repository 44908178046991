.TeamMembers{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -17px;
  margin-right: -17px;
  justify-content: center;

  @include breakpoint("mobile", max) {
    margin-left: -5.5px;
    margin-right: -5.5px;
    justify-content: center;
  }
}

.TeamMember{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 150px;
  margin-left: 17px;
  margin-right: 17px;
  margin-bottom: 60px;
  transition: all 0.2s ease-in-out;
  cursor: pointer !important;
  height: 280px;

  *{
    cursor: inherit;
  }

  &:hover{
    opacity: 0.9;
    transform: scale(1.025);
  }

  @include breakpoint("mobile", max) {
    width: 100%;
    margin-bottom: 0;
    height: 200px;
    margin-left: 5.5px;
    margin-right: 5.5px;
  }

  &__avatar{
    width: 124px;
    border-radius: 100%;
    height: 124px;

    @include breakpoint("mobile", max) {
      width: 80px;
      border-radius: 100%;
      height: 80px;
    }
  }

  &__name{
    margin-top: 16px;
    font-size: 14px;
    font-weight: bold;
    line-height: 17px;
    text-align: center;
    color: white;

    @include breakpoint("mobile", max) {
      font-size: 12px;
      line-height: 14px;
      margin-top: 9px;
    }
  }

  &__title{
    margin-top: 2px;
    font-size: 12px;
    line-height: 17px;
    text-align: center;
    color: #9898AB;
    white-space: pre;

    @include breakpoint("mobile", max) {
      font-size: 10px;
      line-height: 14px;
    }
  }

  &__logo-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    margin-top: 23px;

    @include breakpoint("mobile", max) {
      margin-top: 3px;
      height: 30px;
    }
  }

  &__logo{
    align-self: center;
    height: 100%;
    width: 100%;
    object-fit: scale-down;
  }
}