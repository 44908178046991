.HomePage{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 122px;
  min-height: calc(100vh - 60px);


  @include breakpoint("mobile", max){
    padding-top: 38px;
  }

  .CTAPill{
    margin-right: auto;
    margin-top: 20px;
    width: 340px;
    @include breakpoint("mobile", max){
      width: 320px;
    }
  }

  .BottomNavigator{
    margin-top: auto;
  }

  .SocialIcons{
    margin-top: 10px;
  }

  .TopSection{
    display: flex;
    flex-direction: row;
    max-width: calc(1366px - 120px);
    margin-top: -200px;

    @include breakpoint("mobile", max){
      flex-direction: column-reverse;
    }

    .TextContainer{
      display: flex;
      justify-content: center;
      flex-direction: column;
      min-width: 700px;

      @include breakpoint("tablet-wide", max){
        min-width: 600px;
      }

      @include breakpoint("mobile", max){
        min-width: unset;
        width: 100%;
      }
    }

    .ImageContainer{
      margin-left: 52px;

      img{
        max-height: 698px;
        margin-top: -40px;

        @include breakpoint("mobile", max){
          max-width: 100vw;
          margin-top: -100px;
          height: 330px;
          object-fit: cover;
          width: 100%;
          object-position: bottom;
        }
      }
    }
  }

  .NewsSection{
    display: flex;
    flex-direction: column;
    max-width: calc(1366px - 120px);

    @include breakpoint("mobile", max) {
      margin-top: 0;
    }
  }

  .NewsItems{
    display: flex;
    flex-direction: column;
  }

  .NewsItem{
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    cursor: pointer;

    &__info{
      font-size: 14px;
      font-weight: 600;
      line-height: 17px;
      color: #9898AB;
      cursor: inherit;

      @include breakpoint("mobile", max) {
        font-size: 12px;
      }
    }

    &__title{
      font-size: 24px;
      font-weight: 600;
      line-height: 31px;
      cursor: inherit;

      @include breakpoint("mobile", max) {
        font-size: 16px;
        line-height: 20px;
      }
    }

    &:hover{
      .NewsItem__title{
        color: #9898AB;
      }
    }
  }
}