.RoadmapPage{
  display: flex;
  flex-direction: column;
  align-items: center;

  .RoadmapSection{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow-y: hidden;
    overflow-x: hidden;
    padding-top: calc(60px + $navbar-height);
    padding-bottom: calc(90px + $navbar-height);

    @include breakpoint("mobile", max) {
      padding-top: calc(33px + $navbar-height-mobile);
      padding-bottom: calc(60px + $navbar-height-mobile);
    }

    .SectionContainer{
      max-width: 1365px;

    }

    &__graphic{
      width: 100%;
      max-width: 1182px;
      margin-top: 70px;
      margin-bottom: 70px;
    }

    h1{
      margin-bottom: 12px;
    }

    h1, h2, p{
      text-align: center;
    }

    p{
      max-width: 904px;
      font-size: 16px;
    }
  }

  .RoadmapCards{
    display: flex;
    flex-direction: column;
    margin-top: 60px;

    @include breakpoint("mobile", max) {
      margin-top: 30px;
    }
  }
}

.RoadmapCard{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;

  @include breakpoint("mobile", max) {
    margin-bottom: 20px;
  }

  &__left{
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 32px;
    font-weight: 600;
    margin-right: 26px;
    text-align: right;
    line-height: 38px;

    @include breakpoint("mobile", max){
      font-size: 25px;
      line-height: 30px;
      margin-right: 16px;
    }
  }

  &__right{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 26px;

    @include breakpoint("mobile", max){
      margin-left: 16px;
    }
  }

  &__title{
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 12px;

    @include breakpoint("mobile", max){
      font-size: 14px;
      line-height: 17px;
    }
  }

  &__content{
    font-size: 14px;
    line-height: 22px;
    color: #767685;
    width: 330px;
    white-space: pre-line;
    min-height: 220px;
    display: flex;
    align-items: center; /* 在垂直方向上居中 */

    @include breakpoint("mobile", max){
      font-size: 11px;
      line-height: 14px;
      width: unset;

    }
  }

  &__pill{
    flex: 1;
    margin-left: 11px;
    margin-right: 11px;
    width: 14px;
    min-width: 14px;
    max-width: 14px;
    border-radius: 7px;
    background: #45a7ed;
    background: linear-gradient(180deg, #45a7ed 0%, #4dd8a0 100%);
    height: 100%;
    min-height: 220px;

    @include breakpoint("mobile", max) {
      min-height: 160px;
    }
  }

  &--blue{
    .RoadmapCard__left, .RoadmapCard__title{
      color: #49BBDF;
    }

    .RoadmapCard__pill{
      background: #45a7ed;
      background: linear-gradient(180deg, #45a7ed 0%, #4dd8a0 100%);
    }
  }

  &--green{
    .RoadmapCard__left, .RoadmapCard__title{
      color: #45D155;
    }

    .RoadmapCard__pill{
      background: #45D155;
      background: linear-gradient(180deg, #45D155 0%, #aecc24 100%);
    }
  }

  &--yellow{
    .RoadmapCard__left, .RoadmapCard__title{
      color: #DDC918;
    }

    .RoadmapCard__pill{
      background: #DDC918;
      background: linear-gradient(180deg, #DDC918 0%, #f2903d 100%);
    }
  }

  &--orange{
    .RoadmapCard__left, .RoadmapCard__title{
      color: #F06355;
    }

    .RoadmapCard__pill{
      background: #F06355;
      background: linear-gradient(180deg, #F06355 0%, #f5709b 100%);
    }
  }

  &--pink{
    .RoadmapCard__left, .RoadmapCard__title{
      color: #F088DD;
    }

    .RoadmapCard__pill{
      background: #F088DD;
      background: linear-gradient(180deg, #F088DD 0%, #bb66f4 100%);
    }
  }
}

.RoadmapCards{

}