.SocialIcons{
  display: flex;
  flex-direction: row;

  .SocialIcon{
    display: block;
    margin-right: 8px;
    height: 23px;
    width: 23px;

    @include breakpoint("tablet-wide", max) {
      margin-right: 6px;
    }

    img{
      display: block;
      height: 100%;
      width: 100%;
    }

    &:last-of-type{
      margin-right: 0;
    }
  }
}

