.DocumentsPage{
  display: flex;
  flex-direction: column;
  align-items: center;

  .TopSection{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow-y: hidden;
    overflow-x: hidden;
    padding-top: calc(60px + $navbar-height);
    padding-bottom: calc(90px + $navbar-height);
    height: 100vh;

    @include breakpoint("mobile", max) {
      padding-top: calc(33px + $navbar-height-mobile);
      padding-bottom: calc(60px + $navbar-height-mobile);
    }

    .SectionContainer{
      max-width: 1365px;

    }

    h1{
      max-width: 738px;
      margin-bottom: 75px;
      text-align: center;
    }

    p{
      font-size: 14px;
      line-height: 19px;
      margin-bottom: 14px;
      color: #202224;
    }
  }
}
