.AboutPage{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 85px;

  @include breakpoint("mobile", max){
    padding-top: 38px;
  }

  .TopSection{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-bottom: 50px;
    overflow-y: hidden;
    overflow-x: hidden;
    max-width: 1340px;

    h2, p{
      color: #9898AB;
    }

    .TextContainer{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 872px;

      h1, h2, p{
        text-align: center;
      }

      p{
        max-width: 700px;
      }
    }

    .TopSectionBG{
      position: absolute;
      width: 200px;
      z-index: -1;

      @include breakpoint("tablet-wide", max){
        opacity: 0.12;
      }

      &--left{
        left: 0;
        top: 0;
      }

      &--right{
        right: 0;
        top: 0;
      }
    }
  }

  .TeamSection{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 32px;
    width: unset;

    &__title{
      font-size: 16px;
      font-weight: 600;
      line-height: 19px;
      margin-bottom: 40px;
      text-align: center;
      text-transform: uppercase;
    }
    &__subtitle {
      padding: 10px;
      margin-bottom: 30px;
      text-align: center;
      color: #4499eb;
      font-size: 16px;
      font-weight: 600;
      line-height: 19px;
      justify-self: flex-start;
    }
    &__container {
      display: flex;
      @include breakpoint("mobile", max){
        flex-direction: column;
      }
    }
    &__column {
        flex: 1;
        padding: 20px;
        text-align: center;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    &__divider {
        width: 2px;
        background-color: #a9def9;
    }
    &__content-text {
      // width: 200px;
      height: 50px;
      color: #4499eb;
    }
    &__content-center {
      height: 50px;
      color: #4499eb;
      margin-top: 60px;
      @include breakpoint("mobile", max){
        margin-top: 60px;
      }
    }
  }
}