// Vars
@import "vars";

// Utils
@import "utils/reset";
@import "utils/responsive";
@import "utils/typography";

// Components
@import "components/Button";
@import "components/Page";
@import "components/Section";
@import "components/Navbar";
@import "components/PageBackground";
@import "components/Footer";
@import "components/SocialIcons";
@import "components/TeamMember";
@import "components/Tabs";
@import "components/Modal";
@import "components/Loader";
@import "components/WarningMessage";
@import "components/CTAPill";

// Pages
@import "pages/Home";
@import "pages/About";
@import "pages/Platform";
@import "pages/Components";
@import "pages/Documents";
@import "pages/Roadmap";
@import "pages/Apps";
@import "pages/Stake";
@import "pages/Claim";
@import "pages/News";

// Modals
@import "modals/ConnectWallet";
@import "modals/Alert";
@import "modals/Loader";
@import "modals/Stake";
@import "modals/Unstake";

body {
  margin: 0;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #202224;
  background-color: $color-background;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}

.App{
  //overflow-x: hidden;
}


#root{

}
