.Button{
  position: relative;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  border-radius: 14px;
  height: 36px;
  padding-left: 12px;
  padding-right: 12px;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  will-change: background-color;
  min-width: 90px;
  font-family: $font-family;

  &--transparent-red{
    background-color: transparentize($color-green, 0.8);
    color: $color-green;
    border-radius: 6px;

    &:hover{
      background-color: transparentize($color-green, 0.85);
    }

    &:active{
      background-color: transparentize($color-green, 0.9);
    }
  }

  &--transparent-green{
    background-color: transparentize($color-green, 0.8);
    color: $color-green;
    border-radius: 6px;

    &:hover{
      background-color: transparentize($color-green, 0.85);
    }

    &:active{
      background-color: transparentize($color-green, 0.9);
    }
  }

  &--green{
    background-color: $color-green;
    color: $color-background;

    &:hover{
      background-color: #37F09C;
    }

    &:active{
      background-color: transparentize($color-green, 0.2);
    }
  }

  &--grey{
    background-color: #EEEBF2;
    color: #202224;

    &:hover{
      background-color: transparentize(#EEEBF2, 0.1);
    }

    &:active{
      background-color: transparentize(#EEEBF2, 0.2);
    }
  }

  &--outline-grey{
    background-color: transparent;
    color: #202224;
    border: 1px solid transparentize(#9898AB, 0.35);

    &:hover{
      background-color: transparentize(#9898AB, 0.85);
    }

    &:active{
      background-color: transparentize(#9898AB, 0.9);
    }
  }

  &--disabled{
    background-color: #9898AB40;
    color: #202224;
    cursor: not-allowed;

    &:hover{
      background-color: #9898AB40;
    }

    &:active{
      background-color: #9898AB40;
    }
  }

  &--xsmall{
    height: 22px;
    font-size: 11px;
    min-width: 40px;
    padding-left: 12px;
    padding-right: 12px;
  }

  &--small{
    height: 34px;
    font-size: 12px;
    min-width: 70px;
    padding-left: 16px;
    padding-right: 16px;
  }

  &--large{
    height: 52px;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    padding-left: 60px;
    padding-right: 60px;

    @include breakpoint("mobile", max) {
      font-size: 14px;
      height: 46px;
    }
  }

  &--xlarge{
    height: 66px;
    font-size: 20px;
    line-height: 24px;
  }

  .Loader{
    position: absolute;
  }
}
