// Font weights
$weight-light: 200;
$weight-regular: 300;
$weight-medium: 500;
$weight-semi: 500;
$weight-bold: 700;
$weight-extra-bold: 800;

// Base Font
$font-family: 'Poppins', -apple-system, system-ui, BlinkMacSystemFont, 'Helvetica Neue', 'Helvetica', sans-serif;

@font-face {
  font-family: "Poppins";
  src: url("../../assets/fonts/Poppins/Poppins-Light.ttf") format("ttf"),
  url("../../assets/fonts/Poppins/Poppins-Light.otf") format("opentype");
  font-style: normal;
  font-weight: $weight-light;
}

@font-face {
  font-family: "Poppins";
  src: url("../../assets/fonts/Poppins/Poppins-Regular.ttf") format("truetype"),
  url("../../assets/fonts/Poppins/Poppins-Regular.otf") format("opentype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../../assets/fonts/Poppins/Poppins-Regular.ttf") format("truetype"),
  url("../../assets/fonts/Poppins/Poppins-Regular.otf") format("opentype");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "Poppins";
  src: url("../../assets/fonts/Poppins/Poppins-Medium.ttf") format("truetype"),
  url("../../assets/fonts/Poppins/Poppins-Medium.otf") format("opentype");
  font-style: normal;
  font-weight: $weight-medium;
}

@font-face {
  font-family: "Poppins";
  src: url("../../assets/fonts/Poppins/Poppins-SemiBold.ttf") format("truetype"),
  url("../../assets/fonts/Poppins/Poppins-SemiBold.otf") format("opentype");
  font-style: normal;
  font-weight: $weight-bold;
}

@font-face {
  font-family: "Poppins";
  src: url("../../assets/fonts/Poppins/Poppins-SemiBold.ttf") format("truetype"),
  url("../../assets/fonts/Poppins/Poppins-SemiBold.otf") format("opentype");
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: "Poppins";
  src: url("../../assets/fonts/Poppins/Poppins-Medium.ttf") format("truetype"),
  url("../../assets/fonts/Poppins/Poppins-Medium.otf") format("opentype");
  font-style: normal;
  font-weight: $weight-semi;
}

@font-face {
  font-family: "Poppins";
  src: url("../../assets/fonts/Poppins/Poppins-Bold.ttf") format("truetype"),
  url("../../assets/fonts/Poppins/Poppins-Bold.otf") format("opentype");
  font-style: normal;
  font-weight: $weight-extra-bold;
}
