.Section{
  width: 100%;
  //max-width: calc(1200px + 24px);
  padding-left: 12px;
  padding-right: 12px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  position: relative;

  // Fix the scrollTo issue being under the navbar when it stops
  padding-top: $navbar-height;
  margin-top: calc(-1 * $navbar-height);

  &:last-of-type{
    margin-bottom: 100px;
  }

  @include breakpoint("mobile", max) {
    padding-top: 50px;
    margin-top: -50px;
  }

  h1{
    font-size: 46px;
    line-height: 55px;
    color: #202224;
    margin-bottom: 44px;

    @include breakpoint("mobile", max){
      font-size: 22px;
      line-height: 26px;
      margin-bottom: 26px;
    }
  }

  h2{
    font-size: 13px;
    line-height: 16px;
    color: #767685;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 12px;

    @include breakpoint("mobile", max){
      font-size: 9px;
      line-height: 11px;
      margin-bottom: 9px;
    }
  }

  h3{
    font-size: 18px;
    line-height: 25px;
    color: #202224;
    font-weight: 600;

    @include breakpoint("mobile", max){
      font-size: 12px;
      line-height: 14px;
    }
  }

  p{
    font-size: 16px;
    font-weight: normal;
    line-height: 19px;
    color: #767685;

    @include breakpoint("mobile", max){
      font-size: 14px;
      line-height: 17px;
    }
  }

  &.Section--Dark{
    h1{
      color: white;
    }

    h2{
      color: #767685;

    }

    p{
      color: #767685;
    }
  }
}

.SectionContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: calc(1200px + 24px);
  margin-left: auto;
  margin-right: auto;
}