.CTAPill{
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 7px;
  padding-bottom: 7px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 15px;
  border: 1px solid transparentize(#9898AB, 0.35);
  cursor: pointer;

  @include breakpoint("mobile", max) {
    font-size: 13px;
  }

  *{
    cursor: inherit;
  }
}