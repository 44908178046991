body.ReactModal__Body--open {
  overflow: hidden;
}

.Modal {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: transparent;
  cursor: auto;
  z-index: 1000000;

  &:focus {
    outline: none;
  }
}

.ModalOverlay{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: transparentize(#23232B, 0.15);
  cursor: pointer;
  z-index: 1000000;
}

.RoundedModal{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #FFFFFF;
  border-radius: 20px;
  max-width: 980px;
  width: 100%;
  height: 100vh;
  max-height: 600px;
  box-shadow: 10px 10px 10px #00000026;

  &__content{
    width: 100%;
    height: calc(100% - 72px);
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

.RoundedModalHeader{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  position: relative;

  &__title{
    font-size: 24px;
    font-weight: 600;
    color: #202224;
    line-height: 28px;

    @include breakpoint("tablet", max){
      font-size: 15px;
    }
  }

  &__close-button{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;

    img{
      width: 15.5px;
      height: 15.5px;
      cursor: pointer;
    }
  }
}