.Loader {
  position: relative;
  border-top: 1.1em solid transparent;
  border-right: 1.1em solid transparent;
  border-bottom: 1.1em solid transparent;
  border-left: 1.1em solid $color-green;
  transform: translateZ(0);
  animation: tpmcLoaderSpin 1.1s infinite linear;
  border-radius: 50%;


  &:after{
    border-radius: 50%;
  }

  &--xsmall{
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
    border-width: 2px;
  }

  &--small{
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    border-width: 3px;
  }

  &--medium{
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    border-width: 3px;
  }

  &--large{
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    border-width: 3px;
  }


  &--xlarge{
    width: 56px;
    height: 56px;
    border-width: 3.75px;
  }

  &--center{
    display: block;
    margin: auto;
  }
}


@keyframes tpmcLoaderSpin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

