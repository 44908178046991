.LoaderModal{
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  border-radius: 6px;
  max-width: 980px;
  width: 90px;
  height: 90px;

  &__footer{
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 70px;
    width: 320px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__title{
    font-size: 14px;
    font-weight: bold;
    color: white;
    line-height: 16px;
    margin-top: 22px;
    text-align: center;
  }

  &__subtitle{
    font-size: 12px;
    color: #9898AB;
    line-height: 18px;
    margin-top: 8px;
    text-align: center;
    white-space: pre;
  }

  &__cancel{
    margin-top: 20px;
  }
}