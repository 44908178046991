.PageBackground{
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: fill;
  width: 100%;
  height: 100%;

  &--inline{
    position: unset;
    z-index: unset;
  }
}