.Tabs{

}

.TabList{
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: 0;
  padding-right: 0;
  padding-inline-start: 0;

  .react-tabs__tab{
    display: flex;
    flex-direction: column;
    margin-right: 15px;
    margin-left: 15px;
    cursor: pointer;
    color: #9898AB;
    padding-top: 8px;
    padding-bottom: 8px;
    border: 0 !important;
    outline: none !important;

    &:last-of-type{
      margin-right: 0;
    }

    *{
      cursor: pointer;

    }

    span{
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      text-align: center;
      color: inherit;
    }

    &--selected{
      color: white;
    }
  }
}
