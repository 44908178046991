.PlatformPage{
  display: flex;
  flex-direction: column;
  align-items: center;

  .MarketplaceSection{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow-y: hidden;
    overflow-x: hidden;
    padding-top: calc(60px + $navbar-height);
    padding-bottom: calc(90px + $navbar-height);

    @include breakpoint("mobile", max){
      padding-top: calc(40px + $navbar-height-mobile);
      padding-bottom: calc(47px + $navbar-height-mobile);
    }

    .SectionContainer{
      max-width: 1365px;
    }

    &__graphic{
      width: 100%;
      max-width: 1182px;
      margin-top: 70px;
      margin-bottom: 70px;

      @include breakpoint("mobile", max){
        margin-top: 38px;
        margin-bottom: 38px;
      }
    }

    h1{
      margin-bottom: 12px;

      @include breakpoint("mobile", max){
        margin-bottom: 6px;
      }
    }

    h1, h2, p{
      text-align: center;
    }

    p{
      max-width: 904px;
    }
  }

  .UserWorkflowSection{
    @include breakpoint("mobile", max){
      padding-top: 52px;
      padding-bottom: 47px;
    }
  }

  .PlatformFlowCards{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-around;
    margin-top: 15px;
    margin-left: -20px;
    margin-right: -20px;

    @include breakpoint("mobile", max){
      margin-top: -15px;
    }
  }
}

.PlatformFlowCard{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 240px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 40px;

  @include breakpoint("mobile", max){
    width: 144px;
    margin-left: 12px;
    margin-right: 12px;
    margin-bottom: 40px;
  }

  &__title{
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    line-height: 19px;

    @include breakpoint("mobile", max){
      font-size: 10px;
      line-height: 12px;
    }
  }

  &__image{
    width: 100%;
    margin-bottom: 25px;

    @include breakpoint("mobile", max){
      margin-bottom: 15px;
    }
  }
}