.Navbar{
  position: fixed;
  position: sticky;
  width: 100%;
  background-color: $color-navbar-background;
  height: 80px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  margin-left: auto;
  margin-right: auto;

  .LanguageToggle{
    margin-right: 18px;
  }

  @include breakpoint("mobile", max) {
    height: 60px;
  }

  &__assist-button{
    margin-left: 10px;
    @include breakpoint("900px", max) {
      display: none;
    }
  }

  &__stake-button{
    color: #202224;
    margin-left: 18px;

    @include breakpoint("mobile", max) {
      margin-left: auto;
    }
  }

  &__content{
    display: flex;
    align-items: center;
    max-width: ($max-width + 24px);
    padding-left: 12px;
    padding-right: 12px;
    margin-left: auto;
    margin-right: auto;
    height: 100%;

    @include breakpoint("mobile", max) {
      width: 100%;
      max-width: unset;
    }
  }

  &__links{
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;

    @include breakpoint("mobile", max){
      .NavbarLink{
        display: none;
      }
    }
  }

  &__right{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto;

    .SocialIcons{
      margin-left: 12px;
    }

    @include breakpoint("mobile", max){
      display: none;
    }
  }

  &__right-mobile{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto;
  }

  &__logo-wrapper{
    display: flex;
    align-items: center;

    @include breakpoint("mobile", max){
      margin-left: 45px;
      }
    }

    &__logo{
      cursor: pointer;
      height: 38px;
      margin-right: 15px;

      @include breakpoint("tablet-wide", max){
        margin-right: 0;
        height: 32px;
      }
    }

    .SocialIcons{

      @include breakpoint("mobile", max){
        display: none;
      }
    }
  }

  .NavbarHamburgerButton{
    display: flex;
    position: absolute;
    left: 10px;
    top: 3px;
    height: 54px;
    width: 44px;

    img{
      width: 22px;
      margin: auto;
      display: block;
    }
  }

  .NavbarLink {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #767685;
    font-size: 14px;
    font-weight: 560;
    margin-left: 18px;
    position: relative;
    height: 100%;

    @include breakpoint("1060px", max){
      margin-left: 20px;
    }

    &__submenu {
      display: none;
      position: absolute;
      width: 215px;
      top: 60px;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 10px 10px 10px #00000026;
      border-radius: 20px;
      padding: 36px;
      padding-top: 18px;
      padding-bottom: 18px;

      @include breakpoint("mobile", max){
        // Never show the hover menu on mobile
        display: none !important;
      }
    }

    &__submenu-item {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #767685;
      font-size: 13px;
      font-weight: 500;
      text-align: center;
      height: 50px;
      background-color: white;

      a{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      }

      &:hover {
        color: #202224;
      }
    }

    @include breakpoint("tablet-wide", max){
      font-size: 14px;
      margin-left: 12px;
    }

    &--SubLink{
      font-size: 14px !important;
      margin-left: 30px !important;
      color: #7A7DA3 !important;
    }

    &:hover {
      color: #202224;

      .NavbarLink__submenu {
        display: block;
      }
    }
  }

  .NavBarMenuHeader{
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 20px;
    padding-top: 16px;
    padding-bottom: 20px;
    padding-right: 0;
    margin-bottom: 40px;

    &__close{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 40px;
      margin-bottom: 15px;
      margin-left: auto;

      img{
        width: 16px;
        height: 16px;
      }
    }

    &__social-icons{
      display: flex;
      flex-direction: row;
    }

    .NavBarSocialIcon {
      width: 28px;
      height: 28px;
    }

    .NavbarLink{
      font-size: 18px;
      font-weight: normal;
      line-height: 21px;
      margin-bottom: 32px;
      color: #7A7DA3;
      align-items: unset;

      &--active{
        color: $color-green;

        .NavbarLink__border{
          display: none !important;
        }
      }
    }
  }


  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
  position: fixed;
  height: 100% !important;
  width: 90vw !important;
  top: 0 !important;
  right: initial;
  left: 0 !important;

  [aria-hidden="true"]{
    left: -300 !important;
  }
}

/* General sidebar styles */
.bm-menu {
  background: #37384A !important;
  padding: 0 !important;
  font-size: inherit !important;
  top: 0 !important;

  .NavbarLink{
    margin-right: 20px;
    height: 18px;
    margin-bottom: 30px;
    margin-left: 0;
    font-size: 14px;
    font-weight: 500;
    color: #767685;
  }
}

/* Wrapper for item list */
.bm-item-list {
  padding: 0 !important;

  .SocialIcons{
    display: flex;
    margin-left: 20px;
    margin-right: auto;
  }
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.85) !important;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.BottomNavigator{
  display: none;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  margin-top: -40px;
  z-index: 2;

  @include breakpoint("mobile", max){
    display: flex;
  }

  a{
    font-size: 10px;
    font-weight: 600;
    flex: 1;
    text-align: left;
    justify-self: left;
    color: #767685;
    line-height: 30px;

    &:last-of-type{
      justify-self: right;
      text-align: right;
      margin-left: auto;
    }
  }
}

.LanguageToggle{
  display: flex;
    flex-direction: row;
  align-items: center;

  img{
    width: 15px;
    height: 15px;
    margin-right: 7px;
  }

  .LanguageToggleOption{
    font-size: 13px;
    font-weight: 500;
    color: #9898AB;
    cursor: pointer;

    &:hover{
      color: darken(#9898AB, 10%);
    }

    &--selected{
      color: #202224;
    }
  }

  &__separator{
    width: 1px;
    height: 18px;
    background: #CBCBD5;
    margin-right: 7px;
    margin-left: 7px;
  }
}