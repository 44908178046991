.Page{
  // Adjust for navbar height
  //margin-top: 64px;

  @include breakpoint("mobile", max) {
    //margin-top: 50px;
  }
}

.PageContent{
  max-width: $max-width;
  margin-left: auto;
  margin-right: auto;
}