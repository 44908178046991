.WarningMessage{
    color: #F06355;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    background: white;
    border-radius: 12px;
    padding: 20px;
    max-width: 510px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
    line-height: 20px;
}