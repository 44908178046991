.AppsPage {
  display: flex;
  flex-direction: column;
  align-items: center;

  .TopSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow-y: hidden;
    overflow-x: hidden;
    padding-top: calc(60px + $navbar-height);
    padding-bottom: calc(90px + $navbar-height);
    min-height: 100vh;

    @include breakpoint("mobile", max){
      padding-top: calc(33px + $navbar-height-mobile);
      padding-bottom: 50px;
      min-height: 100vh;
    }

    .SectionContainer {
      max-width: 1365px;

    }

    h1 {
      max-width: 608px;
      margin-bottom: 32px;
      text-align: center;
      color: white;
    }

    p {
      font-size: 14px;
      line-height: 19px;
      margin-bottom: 14px;
      color: #202224;
    }
  }

  .Tabs {
    @include breakpoint("mobile", max) {
      display: none;
    }
  }

  .PlatformApp{
    display: flex;
    flex-direction: row;
    margin-top: 70px;

    @include breakpoint("mobile", max) {
      flex-direction: column;
      margin-top: 0;

    }

    &__demo{
      width: 243px;
      height: 540px;
      margin-right: 92px;
      background-color: #9898AB40;

      @include breakpoint("mobile", max) {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;

        width: calc(243px * 0.8);
        height: calc(540px * 0.8);
      }

      video{
        width: 100%;
        height: 100%;
      }
    }
    &__youtube{
      width: 1141px;
      height: 688px;
      background-color: #9898AB40;

      @include breakpoint("mobile", max) {
        margin-left: 10vw;
        margin-right: 10vw;
        margin-bottom: 30px;

        width: calc(100vw * 0.8);
        height: calc(100vw * 0.8 / 1.6);
      }

      video{
        width: 100%;
        height: 100%;
      }
    }

    &__info{
      display: flex;
      flex-direction: column;
    }

    &__title{
      font-size: 46px;
      line-height: 55px;
      color: white;
      font-weight: 600;
      margin-bottom: 18px;
      max-width: 500px;
      text-align: left;
      position: relative;

      @include breakpoint("mobile", max){
        font-size: 16px;
        line-height: 22px;
        text-align: center;
      }
    }

    &__prev-button, &__next-button{
      width: 30px;
      height: 30px;
      display: none;
      position: absolute;
      top: 0;

      @include breakpoint("mobile", max) {
        display: block;
      }

        img{
        width: 11px;
        height: 22px;
      }
    }

    &__next-button{
      right: 0;

    }

    &__description{
      color: #9898AB;
      font-size: 18px;
      max-width: 500px;
      line-height: 22px;
      white-space: pre-line;

      @include breakpoint("mobile", max){
        font-size: 14px;
        line-height: 17px;
        text-align: center;
      }
    }
  }
}
