.StakeModal{
  max-width: 580px;
  height: auto;
  width: 90%;
  padding-left: 20px;
  padding-right: 20px;

  p{
    font-size: 13px;
    color: #9898AB;
    margin-bottom: 20px;
    margin-top: -20px;
  }

  .AccountBalanceRow{
    max-width: 444px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .ActionInputWrapper{
    max-width: 444px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    margin-top: 34px;
    margin-bottom: 50px;

    .Input{
      flex: 1;
      width: 100%;
      position: relative;
      margin-right: 20px;

      .Button{
        position: absolute;
        right: 6px;
        height: 22px;
        top: 9px;
      }
    }

    input{
      flex: 1;
      width: 100%;
      margin-right: 20px;
      border-radius: 10px;
      height: 40px;
      border: 1px solid rgba(#9898AB, 0.35);
      font-size: 16px;
      font-weight: 600;
      color: #202224;
      padding-left: 18px;
      padding-right: 38px;

      &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: rgba(#9898AB, 0.45);
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: rgba(#9898AB, 0.45);
      }

      &::-ms-input-placeholder { /* Microsoft Edge */
        color: rgba(#9898AB, 0.45);
      }

      &:focus {
        outline: none !important;
        border:1px solid $color-green;
        box-shadow: none;
      }
    }

    .Button{
      flex-basis: 124px;
      border-radius: 10px;
      height: 40px;
      color: #202224;
    }
  }
}