$color-background-home: #F7F7F7;
$color-background: #F7F7F7;
$color-background-dark: #37384A;
$color-navbar-background: #FFFFFF;

$color-text-dark: #202224;
$color-text-light: #767685;

$color-green: #34E393;
$color-red: #F06355;
$color-grey: #9898AB;

$color-border: #2C2F40;

$modal-border-radius: 6px;

$color-input-background: transparent;
$color-input-focus: $color-green;

$color-input-border: $color-border;

$color-input-text: white;

$color-input-placeholder: $color-text-dark;

$max-width: calc(1366px - 60px - 60px);
$max-background-width: 1366px;

$navbar-height: 80px;
$navbar-height-mobile: 60px;

.color--green{
  color: $color-green;
}